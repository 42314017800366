<template>
	<div class="body ">
		<div class="wrap">
			<div class="payInfo">
				<p>交易订单:{{OrderNo}}</p>
				<p>正在使用易票联支付交易，交易将在30分钟后关闭，请及时支付</p>
				<p class="text_1">深圳市米尔斯科技有限公司</p>
			</div>
			<div class="box">
				<div class="top">
					<p>云闪付扫一扫付款</p>
					<!-- <p class="text_1">4035.00</p> -->
				</div>
				<div class="qrcode">
					<vue-qr v-if="ShowQrCode" ref="qrCode" :text="qrcodeUrl" width="250" height="250"></vue-qr>
				</div>
				<div class="OperationSteps">
					<h1>操作步骤</h1>
					<p>1.拿出手机，打开云闪付APP</p>
					<p>2.点击扫一扫</p>
					<p>3.输入支付密码</p>
					<p>4.付款成功</p>
					<p>5.收银台自动刷新页面显示付款成功</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	export default {
		components: {
			VueQr
		},
		data() {
			return {
				qrcodeUrl: '',
				OrderNo: '',
				ShowQrCode: false,
				time: null
			}
		},
		created() {

		},
		mounted() {
			if (!this.$route.query.OrderNo) {
				this.$route.go(-1)
			}
			this.OrderNo = this.$route.query.OrderNo
			if (this.OrderNo != '') this.PayOrder()
		},
		unmounted() {
			clearInterval(this.time)
		},
		methods: {
			async PayOrder() {
				const res = await this.postdata('/Order/OrderAgainPay', {
					OrderNo: this.OrderNo,
					PayType: 4
				})
				if (res.code == 200) {
					var result = res.data
					if (result.returnCode != '0000' || !result.codeUrl) {
						this.notifyError(result.returnMsg);
					} else {
						this.qrcodeUrl = result.codeUrl
						this.ShowQrCode = true

						this.time = setInterval(async () => {
							if (this.$route.name != 'PayQrCode') {
								clearInterval(this.time)
							}
							await this.PaymentQuery()
						}, 2000)
					}
				}
			},
			async PaymentQuery() {
				try {
					const res = await this.postdata('/Order/ePay_PaymentQuery', {
						queryName: this.OrderNo
					})
					if (res.code == 200) {
						var result = res.data
						if (result.returnCode == '0000' && result.payState == '00') {
							clearInterval(this.time)
							this.$alert('恭喜您，订单支付成功。', '支付通知', {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.push("/PersonalCenter/Order")
								}
							});
						}
					}
				} catch {
					clearInterval(this.time)
				}
			}

		}
	}
</script>

<style scoped lang="less">
	.body {
		background-color: hsla(0, 0%, 97%, .98);
		padding: 32px;

		.wrap {
			max-width: 1440px;
			margin: 0 auto;
		}



		.payInfo {
			padding: 16px;
			padding-left: 20%;

			p {
				font-size: 18px;
				margin-bottom: 12px;
			}

			.text_1 {
				color: #09c867;
				font-weight: bold;
			}
		}

		.box {
			background-color: #fff;
			padding: 32px;

			.top {
				text-align: center;

				p {
					font-size: 18px;
				}

				.text_1 {
					color: red;
					font-size: 22px;
					font-weight: bold;
					margin-top: 8px;
				}
			}

			.qrcode {
				width: 250px;
				height: 250px;
				margin: 0 auto;
			}

			.OperationSteps {
				margin: 0 auto;
				width: 250px;

				h1 {
					font-weight: bold;
					color: #09c867;
					margin-bottom: 8px;
				}
			}
		}
	}
</style>